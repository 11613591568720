<template
>
  <div class="survey">
    <div class="row">
      <div class="col-5">
        <v-guard :permissions="['office-information-view', 'office-map-view']">
          <div class="widget">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3>{{ $t('labels.information') }}</h3>
              </div>
              <div class="widget-header-toolbar"></div>
            </div>

          <v-guard :permissions="['office-information-view']">
            <div class="widget-body">
              <div class="row mb-4">
                <div class="col-6">
                  <h6>{{ $t('labels.duty') }} {{ $t('labels.email') }}</h6>
                  {{ localOfficeEmail }}
                </div>
                <div class="col-6">
                  <h6>{{ $t('labels.office') }} {{ $t('labels.email') }}</h6>
                  {{ localOffice.office_email }}
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h6>{{ $t('labels.postal-address') }}</h6>
                  <div>{{ localOffice.addressee }}</div>
                  <div>{{ localOffice.address_1 }}</div>
                  <div>{{ localOffice.address_2 }}</div>
                  <div>{{ localOffice.postal_code }} {{ localOffice.postal_area }}</div>

                </div>
                <div class="col-6">
                  <h6>{{ $t('labels.visiting-address') }}</h6>
                  <div>{{ localOffice.visitor_address_1 }}</div>
                  <div>{{ localOffice.visitor_address_2 }}</div>
                  <div>{{ localOffice.visitor_postal_code }} {{ localOffice.visitor_postal_area }}</div>

                </div>
              </div>
            </div>
          </v-guard>
          <v-guard :permissions="['office-map-view']">
            <div class="widget-body no-padding">
              <GmapMap
                  :center="{lat:coordinates.lat, lng:coordinates.lng}"
                  :zoom="13"
                  :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUi: false,
                }"
                  map-type-id="roadmap"
                  style="width: 100%; height: 300px;"
              >
                <GmapMarker
                  v-if="didFindAddress"
                  :position="{lat:coordinates.lat, lng:coordinates.lng}"
                  :clickable="true"
                  :draggable="true"
                />

              </GmapMap>
            </div>
          </v-guard>
        </div>
        </v-guard>
      </div>
      <div class="col-4 offset-3">
        <v-guard :permissions="['office-comments-view']">
          <div class="widget">
            <div class="widget-header">
              <div class="widget-header-label">
                <h3>
                  {{ $t('labels.contacts-duty') }}

                  <small
                  >{{ localOffice.localofficecomments.length }} {{ $t('labels.events').toLowerCase() }}
                    <span class="d-inline-block ml-3 bl-1 pl-3">{{ totalTimeComments }} {{ $t('labels.minutes-shorten') }}.</span></small
                  >
                </h3>
              </div>
              <div class="widget-header-toolbar">

                <div class="actions">
                  <button type="button" class="btn btn-btn btn-no-outline-white" v-on:click="resetCommentForm"
                          v-if="addComment">
                    <i class="fas fa-times"></i>
                  </button>
                  <v-guard :permissions="['office-comments-add']">
                    <button
                        v-tooltip="this.$t('labels.add-note')"
                        type="button"
                        class="btn btn-btn btn-no-outline-white"
                        v-on:click="showNewCommentForm"
                        v-if="!addComment"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </v-guard>
                </div>

              </div>
            </div>
            <div class="widget-body p-0">
              <div class="new-comment bb-1 mb-4 pb-4" v-if="addComment">
                <div class="">
                  <div class="form-group">
                    <label for="edit-comment_text">{{ $t('labels.comment') }}</label>
                    <textarea-autosize
                        placeholder=""
                        ref="someName"
                        :min-height="90"
                        :max-height="650"
                        class="form-control"
                        v-model="comment.comment"
                        id="edit-comment_text"
                    ></textarea-autosize>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12">
                      <label>{{ $t('labels.contact-via') }}</label>
                      <v-select
                          :options="parentActions"
                          transition="none"
                          :filterable="false"
                          v-model="comment.parentAction"
                          :clearable="false"
                          label="label"
                      ><span slot="no-options">{{ $t('labels.no-values') }}</span></v-select
                      >
                    </div>
                    <div class="form-group col-12">
                      <label>{{ $t('labels.event') }}</label>
                      <v-select
                          :options="childActions"
                          transition="none"
                          :filterable="false"
                          v-model="comment.childAction"
                          :clearable="false"
                          label="label"
                      ><span slot="no-options">{{ $t('labels.no-values') }}</span></v-select
                      >
                    </div>
                    <div class="form-group col-12">
                      <label>{{ $t('labels.time-spent-in-minutes') }}</label>
                      <input type="number" v-model="comment.time_estimate" class="form-control"/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <v-guard :permissions="['office-comments-delete']">
                        <button type="button" @click="deleteComment(comment)" v-if="comment.id !== null"
                                class="btn btn-outline-danger">
                          {{ $t('labels.delete') }}
                        </button>
                      </v-guard>
                    </div>
                    <div class="col-6 text-right">
                      <button type="button" @click="saveNewComment" class="btn btn-success">{{ $t('labels.save') }}</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="widget-body pb-0">
              <div class="empty-placeholder" v-if="localOffice.localofficecomments.length === 0">
                <i class="fas fa-calendar-times"></i>
                {{ $t('labels.no-contact-registered-yet') }}
              </div>
              <div class="row bb-1 pb-4 mb-4" v-for="comment in localOffice.localofficecomments" :key="comment.id">
                <div class="col-12">
                  <div class="row">
                    <div class="col pt-2">
                      <div
                          class="label mr-3 label-xs"
                          :style="{ color: comment.action.parent_action.color, backgroundColor: comment.action.parent_action.color + '1A' }"
                          v-if="comment.action && comment.action.parent"
                      >
                        {{ comment.action.parent_action.label }}
                      </div>
                      <div
                          class="label label-xs"
                          :style="{ color: comment.action.color, backgroundColor: comment.action.color + '0A' }"
                          v-if="comment.action"
                      >
                        {{ comment.action.label }}
                      </div>

                      <div class="mb-3 mt-3">{{ comment.comment }}</div>
                      <div class="list-item text-muted" v-if="comment.next_action">
                        {{ $t('labels.next-planned-action-days', {action: comment.next_action.label, number: comment.suggested_action_time_days}) }}.
                      </div>
                    </div>
                    <v-guard :permissions="['office-comments-edit']">
                      <div class="col-2 text-right" style="line-height: 42px;">
                        <div class="mt-0">
                          <button type="button" class="btn btn-white" @click="editComment(comment)"
                                  style="width: 32px;">
                            <i class="text-muted opacity-60 fas fa-ellipsis-v"></i>
                          </button>
                        </div>
                      </div>
                    </v-guard>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-6 mb-3 list">
                      <div class="list-item">
                        <label>{{ $t('labels.by') }}</label>
                        <span>{{ comment.user.name }}</span>
                      </div>
                    </div>
                    <div class="col-6 list">
                      <div class="list-item">
                        <label>{{ $t('labels.time-spent') }}</label>
                        <span v-if="comment.time_estimate"> {{ comment.time_estimate }} min.</span>
                        <span v-if="!comment.time_estimate"> -</span>
                      </div>
                    </div>
                    <div class="col-6 list">
                      <div class="list-item">
                        <label>{{ $t('labels.created-at') }}</label>
                        <span> {{ comment.created_at_formatted }}</span>
                      </div>
                    </div>
                    <div class="col-6 list">
                      <div class="list-item">
                        <label>{{ $t('labels.updated-at') }}</label>
                        <span> {{ $to(comment,'updated_at_formatted') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-guard>
      </div>
    </div>
  </div>
</template>

<script>
/* global google */

import BojAPI from '@/api/boj';
import TextField from '@/components/ticket/localoffice/TextField';
import TextFieldOffice from '@/components/ticket/office/TextField';
import SelectField from '@/components/ticket/localoffice/SelectField';
import CheckboxField from '@/components/ticket/localoffice/CheckboxField';
import CheckboxFieldOffice from '@/components/ticket/office/CheckboxField';
import TextareaField from '@/components/ticket/localoffice/TextareaField';
import MemberList from '@/components/lists/member/MemberList';
import _ from 'lodash';

export default {
  name: 'survey-page',
  components: {
    TextField,
    TextFieldOffice,
    TextareaField,
    SelectField,
    CheckboxField,
    CheckboxFieldOffice,
    MemberList,
  },
  props: {
    id: Number,
  },

  computed: {
    totalTimeComments() {
      return this.timeConvert(
          _.sumBy(this.comments, function (o) {
            return o.time_estimate ? Number.parseInt(o.time_estimate) : 0;
          }),
      );
    },

    comments() {
      return this.localOffice.localofficecomments;
    },

    localOfficeFieldOptions: function () {
      return this.$store.getters.localOfficeOptions;
    },

    sortedInformationFields() {
      const fields = this.localOffice.information_fields;

      return fields.sort(function (a, b) {
        return a.label < b.label;
      });
    },
    parentActions() {
      const actions = this.commentOptions;
      return actions.filter((item) => item.parent === null);
    },
    childActions() {
      if (this.comment.parentAction !== null) {
        let parentAction = this.comment.parentAction.id;
        const actions = this.commentOptions;
        return actions.filter((item) => item.parent === parentAction);
      } else {
        return [];
      }
    },

    childAction() {
      return this.ticketData.comments.filter((item) => item.id === this.comment.childAction.id);
    },

    localOfficeEmail() {
      if (_.has(this.localOffice, 'office')) {
        return this.localOffice.office.email;
      }

      return '';
    }

  },
  data() {
    return {
      comment: {
        initial_values: false,
        id: null,
        comment: '',
        parentAction: null,
        childAction: null,
        time_estimate: 0,
      },
      addComment: false,
      commentOptions: [],
      isLoading: true,
      localOffice: {
        label: '',
        localofficecomments: [],
      },
      showDetailSection: 1,
      coordinates : { lat : 0, lng : 0},
      didFindAddress: false
    };
  },
  beforeRouteEnter(to, from, next) {
    BojAPI.getLocalOffice(to.params.id)
        .then((response) => {
          next((vm) => {
            vm.setData(response.data);
            vm.geolocation();
            })
        })
        .catch((error) => {
        });
  },
  beforeRouteUpdate(to, from, next) {
    this.localOffice = null;
    BojAPI.getLocalOffice(to.params.id).then((response) => {
      this.setData(response.data);
      this.geolocation();
      next();
    });
  },
  mounted() {
    this.getActions();
  },
  methods: {
    geolocation : function() {
      this.$gmapApiPromiseLazy().then(() => {
        const geocoder = new google.maps.Geocoder()

        const street = this.localOffice.visitor_address_1 != null
        ? this.localOffice.visitor_address_1
        : ( this.localOffice.visitor_address_2 != null
          ? this.localOffice.visitor_address_2
          : ''
        );
        const postalArea = this.localOffice.visitor_postal_area ?? ''
        const postalCode = this.localOffice.visitor_postal_code ?? ''
        let address = street + ' ' + postalArea + ' ' + postalCode;

        if (/^\s*$/.test(address)) {
          this.coordinates.lng = 11.974560;
          this.coordinates.lat = 57.708870;
          this.didFindAddress = false;

          return
        }

        geocoder.geocode({ address }, (results, status) => {
          if (status === 'OK') {
            const latitude = results[0].geometry.location.lat();
            const longitude = results[0].geometry.location.lng();

            this.coordinates.lat = latitude
            this.coordinates.lng = longitude;
            this.didFindAddress = true;
          }

          if (status === 'ZERO_RESULTS') {
            this.coordinates.lng = 11.974560;
            this.coordinates.lat = 57.708870;
            this.didFindAddress = false;
          }
        })
      })
    },

    showNewCommentForm() {
      this.resetCommentForm();
      this.addComment = true;
    },

    resetCommentForm() {

      this.comment = {
        initial_values: false,
        id: null,
        comment: '',
        parentAction: null,
        childAction: null,
        time_estimate: 0,
      };
      this.addComment = false;
    },

    timeConvert(n) {
      return n;
    },


    /**
     * Display Error Message
     */
    showError(value = this.$t('labels.no-permission')) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'warning',
        duration: 3000,
      });
    },

    /**
     * Display Confirmation Message
     */
    showConfirmation(value = this.$t('labels.changes-saved')) {
      this.$notify({
        group: 'foo',
        text: value,
        type: 'success',
        duration: 3000,
      });
    },

    /**
     * Delete comment
     *
     */
    deleteComment(comment) {
      BojAPI.deleteLocalOfficeComment(this.localOffice.id, comment)
          .then(() => {
            this.resetCommentForm();
            BojAPI.getLocalOffice(this.localOffice.id).then((response) => {
              this.setData(response.data);
            });
            this.showConfirmation(this.$t('labels.note-deleted'));
          })
          .catch((error) => {
            this.showError(this.$t('labels.no-permission'))
          });
    },

    /**
     * Edit comment
     */
    editComment(comment) {
      this.addComment = true;
      this.comment = comment;
      this.$set(this.comment, 'parentAction', comment.action.parent_action);
      this.$set(this.comment, 'childAction', comment.action);
      this.$set(this.comment, 'initial_values', true);
    },

    /**
     * Update Information Field.
     *
     * @param localoffice
     * @param field
     * @param event
     */
    updateInformationField(localoffice, field, event) {
      if ((field.checked && field.foreign_id === null && field.date !== null) || field.foreign_id !== null) {
        BojAPI.updateLocalOfficeInformationField(localoffice, field, event.target.value)
            .then((response) => {
              this.setData(response.data);
              this.showConfirmation();
            })
            .catch((error) => {
              this.showError();
            });
      }
    },

    /**
     * Save new comment
     */
    saveNewComment() {
      if (this.comment.parentAction == null || this.comment.childAction == null) {
        this.$notify({
          group: 'foo',
          text: this.$t('labels.missing-forum-event'),
          type: 'error',
          duration: 3000,
        });
      } else {
        BojAPI.saveLocalOfficeComment(this.localOffice.id, this.comment)
            .then(() => {
              BojAPI.getLocalOffice(this.localOffice.id).then((response) => {
                this.setData(response.data);
              });
              this.showConfirmation(this.$t('labels.note-saved'))
              this.resetCommentForm();
            })
            .catch((error) => {
              this.showError();
            });
      }
    },

    /**
     * Get available actions
     */
    getActions() {
      BojAPI.getLocalOfficeActions().then((response) => {
        this.commentOptions = response.data.data;
      });
    },

    /**
     * Update Local Office
     * @param ticket
     */
    updateLocalOffice(ticket) {
      this.$emit('updateLocalOffice', ticket);
    },

    /**
     * Toggle visible section
     *
     * @param section
     */
    setShowDetailSection(section) {
      this.showDetailSection = section;
    },

    /**
     * Assign received data to local state.
     */
    setData(payload) {
      this.localOffice = payload.data;
      this.isLoading = false;
    },
  },
};
</script>
